// This file is generated automatically
// Don't edit this file directly

import { call, toParams } from './utils'

export let api_origin = '/api'

// GET /api/skybox/styles
export function getSkyboxStyleList(input: GetSkyboxStyleListInput): Promise<GetSkyboxStyleListOutput & { error?: string }> {
  return call('GET', api_origin + `/skybox/styles`)
}
export type GetSkyboxStyleListInput = {
}
export type GetSkyboxStyleListOutput = {
  styles: Array<{
    'id': number
    'name': string
    'description': null | string
    'max-char': number
    'negative-text-max-char': number
    'image': null | string
    'image_jpg': null | string
    'model': "Model 2" | "Model 3"
    'model_version': "2" | "3"
    'sort_order': number
    'premium': 0 | 1
    'new': 0 | 1
    'experimental': 0 | 1
    'skybox_style_families_id': null | number
  }>
}
