import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './Library.css'
import { getLatestScenes, searchScene } from '../sdk/scene'

type SceneResult = {
  id: number
  prompt: string
  image_url: string
  music_url: string | null
  submit_time: number
}

function Library() {
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<SceneResult[]>([])
  const [filters, setFilters] = useState({
    style: '',
    date: 'Any',
    hasImage: true,
    hasMusic: false,
    styleEnabled: false,
  })
  const [searchPerformed, setSearchPerformed] = useState(false)

  const [musicIcon, setMusicIcon] = useState(false)

  useEffect(() => {
    getLatestScenes({})
      .then(response => {
        if (response.error) {
          throw new Error(response.error)
        }
        setSearchResults(response.scenes)
      })
      .catch(error => {
        console.error('Failed to fetch scenes:', error)
        setSearchResults([]) // Clear results or handle error appropriately
      })
  }, [])

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    if (name === 'styleEnabled') {
      setFilters({
        ...filters,
        styleEnabled: checked,
        style: checked ? filters.style : '', // Clear style if checkbox is unchecked
      })
    } else {
      if (name === 'hasImage' || name === 'hasMusic') {
        setFilters(prevFilters => ({
          ...prevFilters,
          hasImage: name === 'hasImage' ? checked : !checked,
          hasMusic: name === 'hasMusic' ? checked : !checked,
        }))
      }
    }
  }

  const handleCardClick = (id: number) => () => {
    navigate(`/app/editor/${id}`)
  }

  const performSearch = (event: MouseEvent<HTMLButtonElement>) => {
    setSearchPerformed(true)

    if (filters.hasMusic) {
      setMusicIcon(true)
    } else {
      setMusicIcon(false)
    }

    searchScene({
      body: {
        query: searchQuery,
        image: filters.hasImage,
        styleEnabled: filters.styleEnabled,
        style: filters.style,
      },
    })
      .then(response => {
        if (response.error) {
          throw new Error(response.error)
        }
        setSearchResults(response.scenes)
      })
      .catch(error => {
        console.error('Failed to search scenes:', error)
        setSearchResults([])
      })
  }

  return (
    <>
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search scenes..."
        />
        <button onClick={performSearch}>Search</button>
      </div>
      <div className="filters">
        <label>
          Enable Style:
          <input
            type="checkbox"
            checked={filters.styleEnabled}
            onChange={handleCheckboxChange}
            name="styleEnabled"
          />
        </label>
        <label>
          Style:
          <input
            type="text"
            value={filters.style}
            onChange={handleInputChange}
            disabled={!filters.styleEnabled}
            name="style"
          />
        </label>
        <label>
          Image:
          <input
            type="checkbox"
            name="hasImage"
            checked={filters.hasImage}
            onChange={handleCheckboxChange}
          />
        </label>
        <label>
          Music:
          <input
            type="checkbox"
            name="hasMusic"
            checked={filters.hasMusic}
            onChange={handleCheckboxChange}
          />
        </label>
      </div>
      <div className="card-container">
        {searchResults.map(result => (
          <div
            key={result.id}
            className="scene-card"
            onClick={handleCardClick(result.id)}
          >
            {musicIcon ? (
              <img
                src="https://img.icons8.com/ios/452/music--v1.png"
                alt="music icon"
                className="music-icon"
              />
            ) : (
              <img
                src={result.image_url}
                alt={result.prompt}
                className="scene-image"
              />
            )}
            <div className="scene-info">
              <h2>{result.prompt}</h2>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Library
