import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useToken } from '../../hooks/useToken'
import * as creditAPI from '../../sdk/credit'
import { GetUserProfileOutput, getUserProfile } from '../../sdk/user'
import './Verify.css'


function Login() {
  let navigate = useNavigate()
  const { token, setToken, logout } = useToken()
  const [error, setError] = useState<string | null>(null)
  const [top_up_id, setTopUpId] = useState<number>(0)
  const [top_up_status, setTopUpStatus] = useState<string | null>(null)
  const [profile, setProfile] = useState<GetUserProfileOutput | null>(null)

  useEffect(() => {
    //From server ingest
    let url = new URL(window.location.href)
    setTopUpId(parseInt(url.searchParams.get('top_up_id')!))
    setTopUpStatus(url.searchParams.get('top_up_status'))
    setError(url.searchParams.get('error'))



    //If not logged in, redirect to login page
    if (!(top_up_id > 0 && token)) {
      // !Unhide after bugfix
      setTimeout(() => {
        navigate('/app/Login');
      }, 45000);
    } else {

      //Used to recheck the value if the initial status is pending after the ingest from the server
      async function check_status(_top_up_id: number) {
        let json = await creditAPI.topUpCreditStatus({ params: { top_up_id: _top_up_id } })
        setTopUpStatus(json.top_up_status)
      }

      getUserProfile({}).then(setProfile)

      let retry_counter = 0;
      let max_retries = 5;

      const recheck_status_interval = setInterval(() => {
        check_status(top_up_id);
        retry_counter++;
        if (retry_counter >= max_retries || top_up_status !== 'Pending') {
          clearInterval(recheck_status_interval);
        }
      }, 30000);
      return () => {
        clearInterval(recheck_status_interval)
      }
    }

  }, [token, error, top_up_id, top_up_status])

  return (
    <>
      <div className="body">
        <div style={{ textAlign: "center", position: "absolute", top: "50%", color: "white" }} className="reg-container">
          {token && profile ? (
            <div>
              <h1>Payment Status: {top_up_status} <br /> for Top Up #{top_up_id}</h1>
              <Link to="/app/library">
                <button>Go to Library</button>
              </Link>
            </div>
          ) : (<h2>Error: {error}</h2>)
          }
        </div>
      </div >
    </>
  )
}

export default Login
