import React, { Component } from 'react'
//import HKSTP_Night from '../images/Feelings_HKSTP_Night.jpeg'
//import HKSTP_Morning from '../images/Feelings_HKSTP.jpg'
import {
  generateSceneByGuest,
  getRandomScene,
  waitSceneMusic,
} from '../sdk/scene'
import './Home.css'
// @ts-ignore
import { Pannellum } from 'pannellum-react'
import { startMusic } from '../global/music'
import { later } from '@beenotung/tslib/async/wait'
import { getNextTime } from '../sdk/rate-limit'
import { SECOND } from '@beenotung/tslib/time'
import { GetSkyboxStyleListOutput, getSkyboxStyleList } from '../sdk/skybox'

type Props = {}

type State = {
  image_url: string
  music_url: string | null
  error: string | undefined
  image_style: string
  music_style: string
  image_prompt: string
  music_prompt: string
  is_generating_image: boolean
  is_generating_music: boolean
  has_touch: boolean
  next_time: number
  styles: GetSkyboxStyleListOutput['styles']
}

class Scene extends Component<Props, State> {
  state: State = {
    image_url: '',
    music_url: '',
    error: '',
    image_style: '',
    music_style: '',
    image_prompt: '',
    music_prompt: '',
    is_generating_image: false,
    is_generating_music: false,
    has_touch: false,
    next_time: 0,
    styles: [],
  }
  password = ''

  componentDidMount(): void {
    getSkyboxStyleList({}).then(json => {
      this.update(json)
    })
    this.loadImage()
    //this.getStyles()
    getNextTime({}).then(json => {
      this.setNextTime(json.next_time)
    })
  }
  loadImage = async () => {
    try {
      let json = await getRandomScene({})
      this.update({
        image_url: json.image_url,
        music_url: json.music_url,
        error: json.error,
      })
    } catch (error) {
      this.update({ error: String(error) })
    }
  }
  generate = async () => {
    try {
      let image_style = this.state.image_style
      let music_style = this.state.music_style
      if (!image_style || !music_style) {
        throw new Error('Please select a style')
      }
      let image_prompt = this.state.image_prompt
      let music_prompt = this.state.music_prompt
      if (!prompt) {
        throw new Error('Please input a prompt')
      }
      this.update({
        is_generating_image: true,
        is_generating_music: true,
      })
      let json = await generateSceneByGuest({
        body: {
          image: {
            prompt: image_prompt,
            style: image_style,
          },
          music: {
            prompt: music_prompt,
            style: music_style,
          },
        },
      })
      if (json.error) {
        this.update({
          error: json.error,
          is_generating_image: false,
          is_generating_music: false,
        })
        return
      }
      this.setNextTime(json.next_time)
      if (!json.scene) {
        this.update({
          error: '',
          is_generating_image: false,
          is_generating_music: false,
        })
        return
      }
      let scene = json.scene
      this.update({
        image_url: scene.image_url,
        music_url: scene.music_url,
        error: json.error,
        is_generating_image: false,
      })
      let { scene_id } = scene
      while (true) {
        let json = await waitSceneMusic({ params: { scene_id } })
        if (!json.music_url) {
          await later(1000)
          continue
        }
        this.update({
          music_url: json.music_url,
          is_generating_music: false,
        })
        break
      }
    } catch (error) {
      console.log('cannot generate scene:', error)
      this.update({
        error: String(error),
        is_generating_image: false,
        is_generating_music: false,
      })
    }
  }

  update(state: Partial<State>) {
    if (state.error) {
      // TODO use sweetalert
      alert(state.error)
    }
    if (
      (state.music_url && this.state.has_touch) ||
      (this.state.music_url && state.has_touch)
    ) {
      setTimeout(startMusic)
    }
    this.setState(state as State)
  }

  markHasTouch = () => {
    if (!this.state.has_touch) {
      this.update({ has_touch: true })
    }
  }

  nextTimeInterval?: ReturnType<typeof setInterval>

  setNextTime(next_time: number) {
    this.update({ next_time })
    if (this.nextTimeInterval) {
      clearInterval(this.nextTimeInterval)
      this.nextTimeInterval = undefined
    }
    if (next_time <= Date.now()) return
    this.nextTimeInterval = setInterval(() => {
      this.forceUpdate()
    }, 100)
  }

  renderGenerateButton() {
    let interval = this.state.next_time - Date.now()
    let disabled = this.state.is_generating_image || interval > 0
    return (
      <button
        disabled={disabled}
        onClick={this.generate}
        className="prompt-button"
      >
        {this.state.is_generating_image
          ? 'Generating Scene...'
          : this.state.is_generating_music
          ? 'Generating Music...'
          : interval > 0
          ? `Generate (wait ${(interval / SECOND).toFixed(1)} seconds)`
          : 'Generate'}
      </button>
    )
  }

  render() {
    let music_url = this.state.music_url || ''
    return (
      <>
        <div className="header">
          <audio
            hidden
            controls
            autoPlay
            loop
            src={music_url}
            ref={audio => {
              if (audio) {
                window.addEventListener('touchstart', this.markHasTouch, {
                  once: true,
                })
                window.addEventListener('mousedown', this.markHasTouch, {
                  once: true,
                })
              }
            }}
          />
          <Pannellum
            width="100vw"
            height="100vh"
            image={this.state.image_url}
            yaw={300}
            hfov={110}
            autoLoad
            autoRotate={-5}
            compass={true}
            showZoomCtrl={false}
            mouseZoom={false}
            onLoad={() => {
              console.log('panorama loaded')
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              pitch={-10}
              yaw={-120}
              handleClick={(evt: MouseEvent, args: any) => this.loadImage()}
            />
          </Pannellum>

          <div className="container">
            {/* Upper section for image prompt and style */}
            <div className="upper-section">
              <input
                type="text"
                value={this.state.image_prompt}
                onChange={e => this.update({ image_prompt: e.target.value })}
                placeholder="Enter your image prompt here: "
                className="input-text"
              />
              <input
                placeholder="Select an image style:"
                className="input-text"
                list="styleList"
                value={this.state.image_style}
                onChange={e => this.update({ image_style: e.target.value })}
              />
              <datalist id="styleList">
                {this.state.styles.map(style => (
                  <option>{style.name}</option>
                ))}
              </datalist>
            </div>
            <div className="line"></div>
            {/* Lower section for music prompt and style */}
            <div className="lower-section">
              <input
                type="text"
                value={this.state.music_prompt}
                onChange={e => this.update({ music_prompt: e.target.value })}
                placeholder="Enter your music prompt here: "
                className="input-text"
              />
              <input
                placeholder="Prompt a music style:"
                className="input-text"
                list="musicStyleList"
                value={this.state.music_style}
                onChange={e => this.update({ music_style: e.target.value })}
              />
            </div>
            {this.renderGenerateButton()}
          </div>
        </div>
      </>
    )
  }
}

function Home() {
  return (
    <div className="h-screen flex">
      <Scene />
    </div>
  )
}

export default Home
