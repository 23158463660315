// This file is generated automatically
// Don't edit this file directly

import { call, toParams } from './utils'

export let api_origin = '/api'

// GET /api/rate-limit/next-time
export function getNextTime(input: GetNextTimeInput): Promise<GetNextTimeOutput & { error?: string }> {
  return call('GET', api_origin + `/rate-limit/next-time`)
}
export type GetNextTimeInput = {
}
export type GetNextTimeOutput = {
  next_time: number
}
