import jwtDecode from 'jwt-decode'
import useStorageState from 'react-use-storage-state'
import { JWTPayload } from '../sdk/utils'

export function useToken() {
  const [token, setToken] = useStorageState('token', '')
  function logout() {
    setToken('')
  }
  return { token, setToken, logout }
}

export function useJWTPayload() {
  const { token } = useToken()
  if (!token) return null
  return jwtDecode(token) as JWTPayload
}
