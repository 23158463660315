export function startMusic() {
  let audio = document.querySelector('audio')
  if (audio?.src.endsWith('.mp3')) {
    console.log('playing')
    audio.play()
  }
}

export function pauseMusic() {
  document.querySelector('audio')?.pause()
}
