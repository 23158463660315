import React from 'react'

function Contact() {
  return (
    <>
      <h1 className="mt-40 ml-44 text-4xl font-extrabold">CONTACT PAGE</h1>
    </>
  )
}

export default Contact
