import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
//@ts-ignore
import { Pannellum } from 'pannellum-react'
import { getSceneById, generateSceneByGuest } from '../sdk/scene'
//import Feelings_image from './Feelings_HKSTP.jpg';
import './Editor.css'
import { getSkyboxStyleList } from '../sdk/skybox'
import { useGet } from '../hooks/useGet'

function Editor() {
  const params = useParams<{ scene_id: string }>()
  const scene_id: number | null = +params.scene_id!

  const navigate = useNavigate()

  const [showImage, setShowImage] = useState(true)
  const [showMusic, setShowMusic] = useState(true)
  const [imagePrompt, setImagePrompt] = useState('')
  const [musicPrompt, setMusicPrompt] = useState('')
  const [imageStyleText, setImageStyleText] = useState('Fantasy')
  const [musicStyleText, setMusicStyleText] = useState('')
  const [imageModel, setImageModel] = useState('Skybox')
  const [musicModel, setMusicModel] = useState('Suno')
  const [searchTerm, setSearchTerm] = useState('')

  const [imageUrl, setImageUrl] = useState('')

  const [musicUrl, setMusicUrl] = useState('')

  const skyboxStyleList = useGet([], () =>
    getSkyboxStyleList({}).then(json => json.styles),
  ).state

  const handleSubmission = () => {
    generateSceneByGuest({
      body: {
        image: showImage
          ? {
              prompt: imagePrompt,
              style: imageStyleText,
            }
          : undefined,
        music: showMusic
          ? {
              prompt: musicPrompt,
              style: musicStyleText,
            }
          : undefined,
        edit_scene_id: scene_id,
      },
    }).then(response => {
      if (response.error) {
        console.log(response.error)
      } else {
        navigate(`/app/editor/${response.scene?.scene_id}`)
      }
    })
  }

  const handleSearch = () => {
    navigate(`/app/editor/${searchTerm}`)
  }

  useEffect(() => {
    if (!scene_id) return
    getSceneById({ params: { id: scene_id } }).then(json => {
      setImageUrl(json.image_url)
      setMusicUrl(json.music_url)
    })
  }, [scene_id])

  return (
    <div className="editor">
      <Pannellum
        width="100%"
        height="100vh"
        image={imageUrl}
        pitch={10}
        yaw={300}
        hfov={110}
        autoLoad
        showZoomCtrl={false}
        showFullscreenCtrl={false}
        showControls={false}
      />
      <div className="floating-menu">
        <audio controls autoPlay src={musicUrl} />
        <input
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search by ID"
        />
        <button onClick={handleSearch}>Search</button>
        <label>
          <input
            type="checkbox"
            checked={showImage}
            onChange={() => setShowImage(!showImage)}
          />
          Enable Image
        </label>
        {showImage && (
          <div>
            <input
              value={imagePrompt}
              onChange={e => setImagePrompt(e.target.value)}
              placeholder="Image Prompt"
            />
            <select
              value={imageStyleText}
              onChange={e => setImageStyleText(e.target.value)}
            >
              {skyboxStyleList.map(style => (
                <option value={style.name}>{style.name}</option>
              ))}
            </select>
            <select
              value={imageModel}
              onChange={e => setImageModel(e.target.value)}
            >
              <option value="Skybox">Skybox</option>
            </select>
          </div>
        )}
        <label>
          <input
            type="checkbox"
            checked={showMusic}
            onChange={() => setShowMusic(!showMusic)}
          />
          Enable Music
        </label>
        {showMusic && (
          <div>
            <input
              value={musicPrompt}
              onChange={e => setMusicPrompt(e.target.value)}
              placeholder="Music Prompt"
            />
            <input
              value={musicStyleText}
              onChange={e => setMusicStyleText(e.target.value)}
              placeholder="Music Style"
            />
            <select
              value={musicModel}
              onChange={e => setMusicModel(e.target.value)}
            >
              <option value="Model A">Suno</option>
            </select>
          </div>
        )}
        <button onClick={handleSubmission}>Submit</button>
      </div>
    </div>
  )
}

export default Editor
