import React from 'react'

function About() {
  return (
    <>
      <h1 className="mt-40 ml-44 text-4xl font-extrabold">
        About Feelings AI Group
      </h1>
    </>
  )
}

export default About
