import React from 'react'

function Work() {
  return (
    <>
      <h1 className="mt-40 ml-44 text-4xl font-extrabold">Our Projects</h1>
    </>
  )
}

export default Work
