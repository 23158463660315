import { Link, useLocation } from 'react-router-dom'
import { useStateProxy } from 'use-state-proxy'
import { useState } from 'react'
import { useToken } from '../hooks/useToken'

export function SideBar() {
  const { token } = useToken()

  const [is_open_menu, set_is_open_menu] = useState(true)
  return (
    <div className={'side-menu' + (is_open_menu ? ' open' : '')}>
      <div className="side-menu--header">
        <div
          className="side-menu--logo"
          onClick={() => set_is_open_menu(!is_open_menu)}
        >
          <img alt="Logo" src="/images/feelings.png" />
        </div>
        <div className="side-menu--title">
          <span>Feelings</span>
        </div>
        <button
          className="toggleButton"
          onClick={() => set_is_open_menu(!is_open_menu)}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>
      <nav>
        <ul>
          <SideBarMenuItem
            href="/app/library"
            icon="fa-solid fa-book"
            label="Library"
          />
          <SideBarMenuItem
            href="/app/editor"
            icon="fa-solid fa-pencil"
            label="Editor"
          />
          <SideBarMenuItem
            href="/app/history"
            icon="fa-solid fa-clock-rotate-left"
            label="History"
          />
          <SideBarMenuItem
            href="/premium"
            icon="fa-solid fa-ring"
            label="Premium"
          />
          {/* TODO add profile page */}
          <SideBarMenuItem
            href="/app/login"
            icon="fa-solid fa-user"
            label={token ? 'Logout' : 'Login/Register'}
          />

          <SideBarMenuItem href="/" icon="fa-solid fa-house" label="Home" />
        </ul>
      </nav>
    </div>
  )
}

function SideBarMenuItem(props: { href: string; icon: string; label: string }) {
  const location = useLocation()
  const state = useStateProxy({ is_hover: false })
  return (
    <li>
      <Link
        to={props.href}
        className={`side-menu--item ${
          location.pathname === props.href ? 'selected' : ''
        }`}
        onMouseEnter={() => (state.is_hover = true)}
        onMouseLeave={() => (state.is_hover = false)}
      >
        <i className={'side-menu--icon ' + props.icon}></i>{' '}
        <span className="side-menu--label">{props.label}</span>
      </Link>
    </li>
  )
}
