export let server_origin = 'http://localhost:8100'
if (window.origin.startsWith('https://')) {
  server_origin = window.origin
}

export type JWTPayload = {
  id: number
  is_admin: boolean
}

function getToken(): string | null {
  let text = localStorage.getItem('token')
  if (!text) return null
  return JSON.parse(text)
}

function setToken(token: string) {
  localStorage.setItem('token', JSON.stringify(token))
}

function clearToken() {
  localStorage.removeItem('token')
}

export function call(method: string, href: string, body?: object) {
  let url = server_origin + href
  let init: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getToken(),
    },
  }
  if (body) {
    Object.assign(init.headers!, {
      'Content-Type': 'application/json',
    })
    init.body = JSON.stringify(body)
  }
  return fetch(url, init)
    .then(res => res.json())
    .catch(err => ({ error: String(err) }))
    .then(json => {
      if (json.error) {
        // TODO improve the layout
        alert(json.error)
        return Promise.reject(json.error)
      }
      if (json.token) {
        // TODO remove it?
        setToken(json.token)
      }
      return json
    })
}

export function toParams(input: Record<string, any>) {
  let params = new URLSearchParams()
  for (let key in input) {
    let value = input[key]
    if (Array.isArray(value)) {
      for (let val of value) {
        params.append(key, val)
      }
    } else {
      params.set(key, value)
    }
  }
  return params
}
