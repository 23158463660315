import { Route } from 'react-router-dom'
import SideBarApp from './SideBarApp'
import About from './pages/About'
import Contact from './pages/Contact'
import Editor from './pages/Editor'
import Home from './pages/Home'
import Library from './pages/Library'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import Work from './pages/Work'
import Verify from './pages/top_up/Verify'

export let AppRoutes = [
  <Route path="/" element={<Home />} />,
  <Route path="/app" element={<SideBarApp />}>
    <Route path="library" element={<Library />} />
    <Route path="editor" element={<Editor />} />
    <Route path="editor/:scene_id" element={<Editor />} />
    <Route path="login" element={<Login />} />
  </Route>,
  <Route path="/work" element={<Work />} />,
  <Route path="/about" element={<About />} />,
  <Route path="/contact" element={<Contact />} />,
  <Route path="/top_up/verify" element={<Verify />} />,
  <Route path="*" element={<NotFound />} />,
]
