import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
// document.title = 'Feeling AI'
// const LogoSrc =
//   'https://static.wixstatic.com/media/39fc84_2dee5bfcaafc4de4994a262b2e2be5f2~mv2.png/v1/fill/w_386,h_194,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/FEELINGS-LOGO.png'

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
