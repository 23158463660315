import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './nav.css'
import { pauseMusic } from '../global/music'

interface NavItem {
  label: string
  to: string
}

const Nav: React.FC = () => {
  const location = useLocation()
  const navItems: NavItem[] = [
    {
      label: 'Home',
      to: '/',
    },
    {
      label: 'Editor',
      to: '/app/editor',
    },
    // {
    //   label: 'Work',
    //   to: '/work',
    // },
    // {
    //   label: 'About',
    //   to: '/about',
    // },
    // {
    //   label: 'Contact',
    //   to: '/contact',
    // },
  ]

  return (
    <div className="nav-container">
      <img
        src="https://static.wixstatic.com/media/39fc84_2dee5bfcaafc4de4994a262b2e2be5f2~mv2.png/v1/fill/w_386,h_194,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/FEELINGS-LOGO.png"
        alt="Feelings"
        className="nav-logo"
      />
      <h1 className="nav-title">Feelings AI</h1>
      <nav className="nav-bar">
        {navItems.map(item => (
          <>
            <Link
              className={`nav-item ${
                location.pathname === item.to ? 'nav-item-selected' : ''
              }`}
              to={item.to}
            >
              {item.label}
            </Link>
            |
          </>
        ))}
      </nav>
      <button onClick={pauseMusic}>stop music</button>
    </div>
  )
}

export default Nav
